
import { defineComponent, ref } from "vue";
import Question from "@/components/Pages/TheoryQuestions/Question.vue";
import BackButton from "@/components/Pages/TheoryQuestions/BackButton.vue";
import QuestionHint from "@/components/QuestionHint.vue";
import NextButton from "@/components/NextButton.vue";

export default defineComponent({
  name: "TheoryQuestions",
  components: { NextButton, QuestionHint, BackButton, Question },
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    question: {},
    goBack: {
      type: Function,
      default: () => null,
    },
  },
  emits: ["next", "on-answer"],
  setup(props, { emit }) {
    const showSolutionButton = ref(false);
    const showCorrectAnswers = ref(false);

    const showSolutions = () => {
      showSolutionButton.value = false;
      showCorrectAnswers.value = true;
    };

    const goToNext = () => {
      showCorrectAnswers.value = false;
      emit("next");
    };

    const onAnswer = (answerData: any) => {
      emit("on-answer", answerData);
    };

    return {
      showSolutionButton,
      showCorrectAnswers,
      showSolutions,
      goToNext,
      onAnswer,
    };
  },
});
